import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "./style.css";
import ReactConfetti from "react-confetti";

const Home = () => {
  const eventDate = new Date("2024-12-14T08:40:00").getTime();

  const [isExploding, setIsExploding] = useState(false);

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = eventDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return { days, hours, minutes, seconds };
    }

    if (!isExploding) {
      setIsExploding(true);
    }

    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const numberAnimation = {
    initial: { opacity: 0, y: -20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 },
    transition: { duration: 0.5 },
  };

  return (
    <div class="container">
      {isExploding && <ReactConfetti />}

      <div class="desktop-bg"></div>
      <div class="mobile-bg"></div>

      <div class="overlay">
        <div className="absolute flex justify-center lg:gap-8 gap-4 items-center top-[70.5%]  lg:top-[76%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          {isExploding ? (
            <div>
              <h2 className="lg:text-2xl text-[1rem] text-[#d7b760] font-bold uppercase ">
                Celebration Started!
              </h2>
            </div>
          ) : (
            <>
              {["days", "hours", "minutes", "seconds"].map((unit) => (
                <div key={unit} className="text-center">
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={timeLeft[unit]}
                      {...numberAnimation}
                      className="lg:text-4xl text-[1.5rem] text-[#d7b760]"
                    >
                      {timeLeft[unit]}
                    </motion.div>
                  </AnimatePresence>
                  <span className="text-[0.6rem] lg:text-xs uppercase font-bold text-[#d7b760]">
                    {unit}
                  </span>
                </div>
              ))}
            </>
          )}
        </div>

        <div class="mobile-buttons">
          <button class="btn">
            <a href="https://maps.app.goo.gl/mnCJTnfMw4EekJ3e8">Venue</a>
          </button>
          <button class="btn">
            <a href="tel:8056045835">Query? Contact</a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
